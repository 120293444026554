<template>
	<span
		class="c-umbraco-image-ext"
		:class="{
			'c-umbraco-image-ext--lazyload': lazyload,
			'c-umbraco-image-ext--has-loaded': hasLoaded,
		}"
		:style="computedAspectRatio && `--aspect-ratio: ${computedAspectRatio}`"
	>
		<!-- Image -->
		<UmbracoImage
			v-if="imageAttrs.sourceUrl"
			v-bind="imageAttrs"
			v-on="$listeners"
		/>

		<small
			v-if="credit"
			class="c-umbraco-image-ext__credit"
			v-text="credit"
		></small>
	</span>
</template>

<script>
import UmbracoImage from '@limbo-works/vue-umbraco-image';

export default {
	name: 'UmbracoImageExt',
	components: { UmbracoImage },
	inheritAttrs: false,

	props: {
		...UmbracoImage.props,

		sizes: {
			type: String,
			default: 'auto',
		},
		aspectRatio: {
			type: Number,
			required: false,
		},
		srcWidth: {
			type: Number,
			required: false,
		},
		alt: {
			type: String,
			required: false,
		},
		lazyload: {
			type: Boolean,
			default: true,
		},
		credit: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			hasLoaded: false,
		};
	},

	computed: {
		computedAspectRatio() {
			const { sourceWidth, sourceHeight, aspectRatio } = this;
			const ratio = aspectRatio || sourceWidth / sourceHeight;
			return ratio;
		},
		imageAttrs() {
			return {
				loading: this.lazyload ? 'lazy' : null,
				...this.$attrs,
				...this.$props,
				srcWidth: this.srcWidth || this.widths[0],
				aspectRatio: this.computedAspectRatio,
				transformations: { autoorient: true },
			};
		},
	},

	mounted() {
		// Also only load non-lazy images after load
		if (!this.lazyload) {
			const image = this.$el.querySelector('.c-umbraco-image__image');
			if (image) {
				image.addEventListener(
					'load',
					() => {
						this.hasLoaded = true;
					},
					{
						passive: true,
						once: true,
					}
				);
			} else if (this.useFallback) {
				this.hasLoaded = true;
			}
		}
	},
};
</script>

<style lang="postcss">
.c-umbraco-image-ext {
	position: relative;
	display: inline-block;
	aspect-ratio: var(--aspect-ratio);
}

.c-umbraco-image-ext .c-umbraco-image {
	@apply bg-secondary block overflow-hidden w-full h-full;
	position: relative;
}

.c-umbraco-image-ext__credit {
	@apply absolute right-0 bottom-0 max-w-full bg-black bg-opacity-90 text-white;
	@apply font-darker-grotesque font-semibold text-image-copyright align-middle px-xs;
	padding-top: 3px;
	padding-bottom: 7px;

	&:before {
		@apply relative text-image-copyright-icon font-bold;
		content: '\00a9';
		margin-right: 0.3em;
		top: 1px;
	}
}

.c-umbraco-image-ext .c-umbraco-image__image {
	@apply absolute w-full h-full top-0 left-0;
	@apply opacity-0 transform;
	@apply duration-500 ease-smooth-out;
}
.c-umbraco-image-ext--lazyload .c-umbraco-image__image.is-lazyloaded,
.c-umbraco-image-ext--has-loaded .c-umbraco-image__image {
	@apply opacity-100;
}
</style>
