<template>
	<div v-if="negative && positive" class="c-feedback-module">
		<div class="w-full >=1024:w-8/12col mx-auto">
			<div class="relative h-24">
				<Transition name="t-feedback-module__label">
					<span
						:key="activeOption"
						:class="[
							'c-feedback-module__label',
							'block w-full',
							'absolute top-0 left-1/2 transform -translate-x-1/2',
							'font-darker-grotesque font-semibold text-center text-button',

							activeOption
								? 'c-feedback-module__label--direction-up'
								: 'c-feedback-module__label--direction-down',
						]"
						v-text="
							(activeOption &&
								activeConfig &&
								activeConfig.overline) ||
							title
						"
					></span>
				</Transition>
			</div>

			<div
				:class="[
					'c-feedback-module__buttons',
					'relative flex gap-x-16 justify-center',
					'z-10 mt-md max-w-fit mx-auto',

					{
						'c-feedback-module__buttons--left-active':
							activeOption === 'positive',
						'c-feedback-module__buttons--right-active':
							activeOption === 'negative',
						'pointer-events-none': activeOption,
					},
				]"
			>
				<button
					aria-label="Denne side hjalp mig"
					:class="{ active: activeOption === 'positive' }"
					:tabindex="
						activeOption && activeOption !== 'positive' ? '-1' : '0'
					"
					:aria-disabled="
						activeOption && activeOption !== 'positive'
							? 'true'
							: 'false'
					"
					@click.prevent="onPositiveClick"
				>
					<SvgThumbsUp class="mb-2" />
				</button>

				<button
					aria-label="Denne side hjalp mig ikke"
					:class="{ active: activeOption === 'negative' }"
					:tabindex="
						activeOption && activeOption !== 'negative' ? '-1' : '0'
					"
					:aria-disabled="
						activeOption && activeOption !== 'negative'
							? 'true'
							: 'false'
					"
					@click.prevent="onNegativeClick"
				>
					<SvgThumbsDown class="mt-2" />
				</button>
			</div>

			<div
				ref="content"
				:aria-hidden="activeOption ? 'false' : 'true'"
				:class="[
					'c-feedback-module__content',
					'relative -mt-32 bg-background overflow-hidden',
					{ 'c-feedback-module__content--active': !!activeOption },
				]"
			>
				<Transition name="t-feedback-module__content">
					<div v-if="!success" key="content">
						<button
							:tabindex="activeOption ? 0 : -1"
							class="absolute top-md right-md text-text"
							@click.prevent="onClearClick"
						>
							<SvgClose class="pointer-events-none w-14" />
						</button>

						<Transition :duration="500">
							<form
								v-if="activeOption"
								ref="form"
								class="p-2xl pt-4xl"
								@submit.prevent="onSubmit"
							>
								<h2
									class="text-h2 font-darker-grotesque"
									v-text="activeConfig.title"
								></h2>

								<p
									class="text-body mt-lg"
									v-text="activeConfig.description"
								></p>

								<!-- Comment -->
								<div
									v-if="computedFields.comment"
									class="flex flex-col gap-y-16 mt-3xl"
								>
									<label
										:class="[
											'text-h4 ml-8',
											'font-darker-grotesque font-semibold',
										]"
										:for="`${_uid}-${computedFields.comment.name}`"
									>
										<span
											v-text="
												computedFields.comment.label
											"
										></span>
										<span
											class="text-error"
											v-text="' *'"
										></span>
									</label>

									<textarea
										:id="`${_uid}-${computedFields.comment.name}`"
										v-bind="computedFields.comment"
										v-model="values.comment"
										required
									></textarea>
								</div>

								<div
									:class="[
										'flex gap-x-layout-gutter gap-y-xl',
										'mt-xl flex-col >=1024:flex-row',
									]"
								>
									<!-- Name -->
									<div
										v-if="computedFields.name"
										class="flex-1 flex flex-col gap-y-16"
									>
										<label
											:class="[
												'text-h4 ml-8',
												'font-darker-grotesque font-semibold',
											]"
											:for="`${_uid}-${computedFields.name.name}`"
										>
											<span
												v-text="
													computedFields.name.label
												"
											></span>
											<span
												v-if="
													computedFields.name.required
												"
												class="text-error"
												v-text="' *'"
											></span>
										</label>

										<input
											:id="`${_uid}-${computedFields.name.name}`"
											v-bind="computedFields.name"
											v-model="values.name"
										/>
									</div>

									<!-- Email -->
									<div
										v-if="computedFields.email"
										class="flex-1 flex flex-col gap-y-16"
									>
										<label
											:class="[
												'text-h4 ml-8',
												'font-darker-grotesque font-semibold',
											]"
											:for="`${_uid}-${computedFields.email.name}`"
										>
											<span
												v-text="
													computedFields.email.label
												"
											></span>
											<span
												v-if="
													computedFields.email
														.required
												"
												class="text-error"
												v-text="' *'"
											></span>
										</label>

										<input
											:id="`${_uid}-${computedFields.email.name}`"
											v-bind="computedFields.email"
											v-model="values.email"
										/>
									</div>
								</div>

								<BaseButton
									large
									:class="[
										'w-full mt-3xl',
										'duration-300 ease-smooth-out',
										{ 'opacity-50': loading },
									]"
									:disabled="loading"
									v-text="'SEND FEEDBACK'"
								/>
							</form>
						</Transition>
					</div>

					<div
						v-else
						key="success"
						ref="success"
						class="absolute top-0 left-0 w-full px-2xl py-4xl"
					>
						<h2
							class="text-h2 text-center font-darker-grotesque"
							v-text="activeConfig.success"
						></h2>
					</div>
				</Transition>
			</div>
		</div>
	</div>
</template>

<script>
import SvgThumbsUp from '~/assets/svgs/icon-thumbs-up.svg?inline';
import SvgThumbsDown from '~/assets/svgs/icon-thumbs-down.svg?inline';
import SvgClose from '~/assets/svgs/icon-close.svg?inline';

export default {
	name: 'FeedbackModule',

	components: {
		SvgThumbsUp,
		SvgThumbsDown,
		SvgClose,
	},

	props: {
		feedback: {
			type: Object,
			default: null,
		},
	},

	data() {
		const data = this.feedback || {};

		if (data?.negative && data?.positive) {
			data.negative.overline = 'Vi har brug for mere info!';
			data.positive.overline = 'Tak!';
		}

		return {
			negative: null,
			positive: null,
			...data,
			activeConfig: null,
			activeOption: null,
			values: {},

			token: null,
			loading: false,
			success: false,
		};
	},

	computed: {
		computedFields() {
			return this.activeConfig?.fields?.reduce(
				(acc, cur) => ({ ...acc, [cur.name]: cur }),
				{}
			);
		},
	},

	watch: {
		activeOption() {
			this.$nextTick(() => {
				this.updateContainerHeight();
			});
		},

		success() {
			this.$nextTick(() => {
				this.updateContainerHeight();
			});
		},

		activeConfig(value) {
			this.values = value?.fields?.reduce(
				(acc, cur) => ({ ...acc, [cur.name]: '' }),
				{}
			);
		},
	},

	methods: {
		onPositiveClick() {
			this.activeConfig = this.positive;
			this.activeOption = 'positive';
			!this.token && this.submitRating();
		},

		onNegativeClick() {
			this.activeConfig = this.negative;
			this.activeOption = 'negative';
		},

		onClearClick() {
			this.activeOption = null;
		},

		onSubmit() {
			this.error = null;
			this[this.token ? 'updateComment' : 'submitComment']?.();
		},

		submitRating() {
			if (this.state !== this.negativeResponse) {
				this.$axios
					.post(this.ratingEndpoint, {
						rating: this.positiveResponse,
						siteKey: this.siteKey,
						pageKey: this.pageKey,
					})
					.then((response) => {
						this.token = response.data.key;
					})
					.catch((error) => {
						this.error = error;
						console.error(error);
					});
			}
		},

		updateComment() {
			if (this.token) {
				this.loading = true;

				this.$axios
					.post(this.updateEndpoint.replace('{key}', this.token), {
						rating:
							this.activeOption === 'positive'
								? this.positiveResponse
								: this.negativeResponse,

						siteKey: this.siteKey,
						pageKey: this.pageKey,
						comment: this.values.comment,
						name: this.values.name,
						email: this.values.email,
					})
					.then(() => {
						this.loading = false;
						this.success = true;
					})
					.catch((error) => {
						this.error = error;
						console.error(error);
					});
			}
		},

		submitComment() {
			this.loading = true;

			this.$axios
				.post(this.commentEndpoint, {
					rating:
						this.activeOption === 'positive'
							? this.positiveResponse
							: this.negativeResponse,

					siteKey: this.siteKey,
					pageKey: this.pageKey,
					comment: this.values.comment,
					name: this.values.name,
					email: this.values.email,
				})
				.then(() => {
					this.loading = false;
					this.success = true;
				})
				.catch((error) => {
					this.error = error;
					console.error(error);
				});
		},

		updateContainerHeight() {
			const { content, form, success } = this.$refs;
			content.style.height = '0px';

			const innerActual = success ?? form;
			if (content && innerActual && this.activeOption) {
				const { height } = innerActual.getBoundingClientRect();
				content.style.height = `${height}px`;
			}
		},
	},
};
</script>

<style lang="postcss">
.c-feedback-module__buttons {
	@apply duration-500 ease-silk-out;

	&.c-feedback-module__buttons--left-active {
		transform: translateX(40px);
		& > button:nth-child(2) {
			@apply opacity-0;
		}
	}

	&.c-feedback-module__buttons--right-active {
		transform: translateX(-40px);
		& > button:nth-child(1) {
			@apply opacity-0;
		}
	}
}

.c-feedback-module__buttons button {
	@apply relative overflow-hidden;
	@apply duration-500 ease-silk-out;
	@apply flex justify-center items-center;
	@apply bg-text w-64 h-64 rounded-full;
	@apply text-white;

	& > svg {
		@apply duration-500 ease-silk-out;
		@apply h-32 transform;
	}

	&:nth-child(1) > svg {
		transform-origin: 0% 65%;
	}
	&:nth-child(2) > svg {
		transform-origin: 100% 35%;
	}

	&::before {
		@apply absolute top-0 left-0;
		@apply w-full h-full rounded-full;
		@apply bg-white bg-opacity-10;
		@apply transform -translate-x-full;
		@apply duration-500 ease-silk-out;
		z-index: 0;
		content: '';
	}

	&:hover::before,
	&.active::before {
		@apply translate-x-0;
	}

	&:hover > svg,
	&.active > svg {
		@apply -rotate-10;
	}
}

.c-feedback-module__content {
	@apply duration-500 ease-silk-out opacity-20;
}
.c-feedback-module__content--active {
	@apply min-h-fit opacity-100;
}

.c-feedback-module form .c-base-button__inner {
	@apply h-60 font-bold text-button-xs w-full mx-0;
}

.c-feedback-module form input,
.c-feedback-module form textarea {
	@apply font-darker-grotesque font-medium text-input;
	@apply bg-white rounded-full pt-xs pb-sm px-lg;
	@apply border-2 border-text;
}

.c-feedback-module form textarea {
	@apply rounded-md h-140;
}

/**
	Transitions
 */
.t-feedback-module__label-enter-active,
.t-feedback-module__label-leave-active {
	@apply duration-500 ease-silk-out;
}

.t-feedback-module__label-enter.c-feedback-module__label--direction-down,
.t-feedback-module__label-leave-to.c-feedback-module__label--direction-down {
	transform: translate(-50%, -20px) !important;
	@apply opacity-0;
}

.t-feedback-module__label-enter.c-feedback-module__label--direction-up,
.t-feedback-module__label-leave-to.c-feedback-module__label--direction-up {
	transform: translate(-50%, 20px) !important;
	@apply opacity-0;
}

.t-feedback-module__content-enter-active,
.t-feedback-module__content-leave-active {
	/* transition: 30000ms ease-out; */
	@apply duration-500 ease-silk-out;
}

.t-feedback-module__content-enter {
	transform: translateY(128px);
	@apply opacity-0;
}
.t-feedback-module__content-leave-to {
	transform: translateY(-128px);
	@apply opacity-0;
}
</style>
