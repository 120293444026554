var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.negative && _vm.positive)?_c('div',{staticClass:"c-feedback-module"},[_c('div',{staticClass:"w-full >=1024:w-8/12col mx-auto"},[_c('div',{staticClass:"relative h-24"},[_c('Transition',{attrs:{"name":"t-feedback-module__label"}},[_c('span',{key:_vm.activeOption,class:[
						'c-feedback-module__label',
						'block w-full',
						'absolute top-0 left-1/2 transform -translate-x-1/2',
						'font-darker-grotesque font-semibold text-center text-button',

						_vm.activeOption
							? 'c-feedback-module__label--direction-up'
							: 'c-feedback-module__label--direction-down',
					],domProps:{"textContent":_vm._s(
						(_vm.activeOption &&
							_vm.activeConfig &&
							_vm.activeConfig.overline) ||
						_vm.title
					)}})])],1),_vm._v(" "),_c('div',{class:[
				'c-feedback-module__buttons',
				'relative flex gap-x-16 justify-center',
				'z-10 mt-md max-w-fit mx-auto',

				{
					'c-feedback-module__buttons--left-active':
						_vm.activeOption === 'positive',
					'c-feedback-module__buttons--right-active':
						_vm.activeOption === 'negative',
					'pointer-events-none': _vm.activeOption,
				},
			]},[_c('button',{class:{ active: _vm.activeOption === 'positive' },attrs:{"aria-label":"Denne side hjalp mig","tabindex":_vm.activeOption && _vm.activeOption !== 'positive' ? '-1' : '0',"aria-disabled":_vm.activeOption && _vm.activeOption !== 'positive'
						? 'true'
						: 'false'},on:{"click":function($event){$event.preventDefault();return _vm.onPositiveClick.apply(null, arguments)}}},[_c('SvgThumbsUp',{staticClass:"mb-2"})],1),_vm._v(" "),_c('button',{class:{ active: _vm.activeOption === 'negative' },attrs:{"aria-label":"Denne side hjalp mig ikke","tabindex":_vm.activeOption && _vm.activeOption !== 'negative' ? '-1' : '0',"aria-disabled":_vm.activeOption && _vm.activeOption !== 'negative'
						? 'true'
						: 'false'},on:{"click":function($event){$event.preventDefault();return _vm.onNegativeClick.apply(null, arguments)}}},[_c('SvgThumbsDown',{staticClass:"mt-2"})],1)]),_vm._v(" "),_c('div',{ref:"content",class:[
				'c-feedback-module__content',
				'relative -mt-32 bg-background overflow-hidden',
				{ 'c-feedback-module__content--active': !!_vm.activeOption },
			],attrs:{"aria-hidden":_vm.activeOption ? 'false' : 'true'}},[_c('Transition',{attrs:{"name":"t-feedback-module__content"}},[(!_vm.success)?_c('div',{key:"content"},[_c('button',{staticClass:"absolute top-md right-md text-text",attrs:{"tabindex":_vm.activeOption ? 0 : -1},on:{"click":function($event){$event.preventDefault();return _vm.onClearClick.apply(null, arguments)}}},[_c('SvgClose',{staticClass:"pointer-events-none w-14"})],1),_vm._v(" "),_c('Transition',{attrs:{"duration":500}},[(_vm.activeOption)?_c('form',{ref:"form",staticClass:"p-2xl pt-4xl",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('h2',{staticClass:"text-h2 font-darker-grotesque",domProps:{"textContent":_vm._s(_vm.activeConfig.title)}}),_vm._v(" "),_c('p',{staticClass:"text-body mt-lg",domProps:{"textContent":_vm._s(_vm.activeConfig.description)}}),_vm._v(" "),(_vm.computedFields.comment)?_c('div',{staticClass:"flex flex-col gap-y-16 mt-3xl"},[_c('label',{class:[
										'text-h4 ml-8',
										'font-darker-grotesque font-semibold',
									],attrs:{"for":`${_vm._uid}-${_vm.computedFields.comment.name}`}},[_c('span',{domProps:{"textContent":_vm._s(
											_vm.computedFields.comment.label
										)}}),_vm._v(" "),_c('span',{staticClass:"text-error",domProps:{"textContent":_vm._s(' *')}})]),_vm._v(" "),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.values.comment),expression:"values.comment"}],attrs:{"id":`${_vm._uid}-${_vm.computedFields.comment.name}`,"required":""},domProps:{"value":(_vm.values.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.values, "comment", $event.target.value)}}},'textarea',_vm.computedFields.comment,false))]):_vm._e(),_vm._v(" "),_c('div',{class:[
									'flex gap-x-layout-gutter gap-y-xl',
									'mt-xl flex-col >=1024:flex-row',
								]},[(_vm.computedFields.name)?_c('div',{staticClass:"flex-1 flex flex-col gap-y-16"},[_c('label',{class:[
											'text-h4 ml-8',
											'font-darker-grotesque font-semibold',
										],attrs:{"for":`${_vm._uid}-${_vm.computedFields.name.name}`}},[_c('span',{domProps:{"textContent":_vm._s(
												_vm.computedFields.name.label
											)}}),_vm._v(" "),(
												_vm.computedFields.name.required
											)?_c('span',{staticClass:"text-error",domProps:{"textContent":_vm._s(' *')}}):_vm._e()]),_vm._v(" "),(((_vm.computedFields.name).type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.values.name),expression:"values.name"}],attrs:{"id":`${_vm._uid}-${_vm.computedFields.name.name}`,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.values.name)?_vm._i(_vm.values.name,null)>-1:(_vm.values.name)},on:{"change":function($event){var $$a=_vm.values.name,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.values, "name", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.values, "name", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.values, "name", $$c)}}}},'input',_vm.computedFields.name,false)):(((_vm.computedFields.name).type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.values.name),expression:"values.name"}],attrs:{"id":`${_vm._uid}-${_vm.computedFields.name.name}`,"type":"radio"},domProps:{"checked":_vm._q(_vm.values.name,null)},on:{"change":function($event){return _vm.$set(_vm.values, "name", null)}}},'input',_vm.computedFields.name,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.values.name),expression:"values.name"}],attrs:{"id":`${_vm._uid}-${_vm.computedFields.name.name}`,"type":(_vm.computedFields.name).type},domProps:{"value":(_vm.values.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.values, "name", $event.target.value)}}},'input',_vm.computedFields.name,false))]):_vm._e(),_vm._v(" "),(_vm.computedFields.email)?_c('div',{staticClass:"flex-1 flex flex-col gap-y-16"},[_c('label',{class:[
											'text-h4 ml-8',
											'font-darker-grotesque font-semibold',
										],attrs:{"for":`${_vm._uid}-${_vm.computedFields.email.name}`}},[_c('span',{domProps:{"textContent":_vm._s(
												_vm.computedFields.email.label
											)}}),_vm._v(" "),(
												_vm.computedFields.email
													.required
											)?_c('span',{staticClass:"text-error",domProps:{"textContent":_vm._s(' *')}}):_vm._e()]),_vm._v(" "),(((_vm.computedFields.email).type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.values.email),expression:"values.email"}],attrs:{"id":`${_vm._uid}-${_vm.computedFields.email.name}`,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.values.email)?_vm._i(_vm.values.email,null)>-1:(_vm.values.email)},on:{"change":function($event){var $$a=_vm.values.email,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.values, "email", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.values, "email", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.values, "email", $$c)}}}},'input',_vm.computedFields.email,false)):(((_vm.computedFields.email).type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.values.email),expression:"values.email"}],attrs:{"id":`${_vm._uid}-${_vm.computedFields.email.name}`,"type":"radio"},domProps:{"checked":_vm._q(_vm.values.email,null)},on:{"change":function($event){return _vm.$set(_vm.values, "email", null)}}},'input',_vm.computedFields.email,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.values.email),expression:"values.email"}],attrs:{"id":`${_vm._uid}-${_vm.computedFields.email.name}`,"type":(_vm.computedFields.email).type},domProps:{"value":(_vm.values.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.values, "email", $event.target.value)}}},'input',_vm.computedFields.email,false))]):_vm._e()]),_vm._v(" "),_c('BaseButton',{class:[
									'w-full mt-3xl',
									'duration-300 ease-smooth-out',
									{ 'opacity-50': _vm.loading },
								],attrs:{"large":"","disabled":_vm.loading},domProps:{"textContent":_vm._s('SEND FEEDBACK')}})],1):_vm._e()])],1):_c('div',{key:"success",ref:"success",staticClass:"absolute top-0 left-0 w-full px-2xl py-4xl"},[_c('h2',{staticClass:"text-h2 text-center font-darker-grotesque",domProps:{"textContent":_vm._s(_vm.activeConfig.success)}})])])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }